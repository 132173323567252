import { IStructuredData, IStructuredDataContent, ICategory, IErrorItem, IStructuredDataQueryValues } from "@ax/types";
import {
  SET_CATEGORIES,
  SET_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA_ID,
  SET_CURRENT_STRUCTURED_DATA_CONTENTS,
  SET_CATEGORY,
  SET_SCHEMA,
  CREATE_FORM,
  UPDATE_FORM,
  SET_IS_ACTIVE,
  SET_ENTITY,
  SET_FILTER,
  SET_SCHEMA_VERSION,
  SET_ERRORS,
  SET_VALIDATED,
  SET_CONTENT_FILTERS,
  SET_IS_IA_TRANSLATED,
} from "./constants";

import { StructuredDataActionsCreators } from "./interfaces";

export interface IStructuredDataState {
  isActive: boolean;
  categories: { global: IStructuredData[]; site: IStructuredData[] };
  structuredData: { global: IStructuredData[]; site: IStructuredData[] };
  currentStructuredData: IStructuredData | null;
  currentStructuredDataId: number | null;
  currentDataContent: IStructuredDataContent[];
  category: ICategory;
  schema: any;
  form: any;
  entity: string | null;
  currentFilter: string;
  schemaVersion: string;
  errors: IErrorItem[];
  validated: boolean;
  contentFilters: Record<string, IStructuredDataQueryValues> | null;
  isIATranslated: boolean;
}

export const initialState = {
  isActive: false,
  categories: { global: [], site: [] },
  structuredData: { global: [], site: [] },
  currentStructuredData: null,
  currentStructuredDataId: null,
  currentDataContent: [],
  category: { name: "", code: "", lang: null, isTranslation: false, isNew: true },
  schema: {},
  form: {},
  entity: null,
  currentFilter: "all-pages",
  schemaVersion: "",
  errors: [],
  validated: false,
  contentFilters: null,
  isIATranslated: false,
};

export function reducer(state = initialState, action: any): IStructuredDataState {
  switch (action.type) {
    case SET_CATEGORIES:
    case SET_STRUCTURED_DATA:
    case SET_CURRENT_STRUCTURED_DATA:
    case SET_CURRENT_STRUCTURED_DATA_ID:
    case SET_CURRENT_STRUCTURED_DATA_CONTENTS:
    case SET_CATEGORY:
    case SET_SCHEMA:
    case CREATE_FORM:
    case UPDATE_FORM:
    case SET_IS_ACTIVE:
    case SET_ENTITY:
    case SET_FILTER:
    case SET_SCHEMA_VERSION:
    case SET_ERRORS:
    case SET_VALIDATED:
    case SET_CONTENT_FILTERS:
    case SET_IS_IA_TRANSLATED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as structuredDataInitialState, reducer as structuredDataReducer };
