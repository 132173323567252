const getFileIcon = (type: string) => {
  switch (type) {
    case "pdf":
      return "pdf.png";
    case "doc":
    case "docx":
      return "word.png";
    case "xls":
    case "xlsx":
    case "csv":
      return "excel.png";
    case "zip":
      return "zip.png";
    default:
      return "zip.png";
  }
};

export { getFileIcon };
