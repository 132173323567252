import styled from "styled-components";

export const FieldGroup = styled.div<{ full?: boolean }>`
  width: 100%;
  max-width: calc(${(p) => p.theme.spacing.xl} * 5);
  max-height: ${(p) => (p.full ? "auto" : `calc(${p.theme.spacing.l} * 3)`)};
  overflow: auto;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  & > div {
    margin-bottom: ${p => p.theme.spacing.xxs}
  }
`;
