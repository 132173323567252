import {
  SET_SITES,
  SET_RECENT_SITES,
  SET_SITES_TOTAL_ITEMS,
  SET_SITES_BY_LANG,
  SET_CURRENT_SITE_INFO,
  SET_CURRENT_SITE_PAGES,
  SET_FILTER,
  SET_TOTAL_ITEMS,
  SET_CURRENT_SITE_LANGUAGES,
  SET_INITIAL_VALUES,
  SET_SAVED_SITE_INFO,
  SET_CURRENT_SITE_ERROR_PAGES,
  SET_CONTENT_FILTERS,
  SET_CONFIG,
} from "./constants";

import { ISite, IPage, ILanguage, ISiteListConfig, IQueryValue } from "@ax/types";

import { SitesActionsCreators } from "./interfaces";

export interface ISitesState {
  currentSiteName: string | null;
  currentSitePages: IPage[];
  sites: ISite[];
  recentSites: ISite[];
  sitesTotalItems: number;
  sitesByLang: ISite[];
  currentSiteInfo: ISite | null;
  currentFilter: string | null;
  totalItems: number;
  currentSiteLanguages: ILanguage[];
  savedSiteInfo: any;
  currentSiteErrorPages: number[];
  contentFilters: Record<string, IQueryValue[]> | null;
  config: ISiteListConfig;
}

const config = {
  displayRecentSites: false,
  mode: "grid",
  filter: "&order=lastAccess-desc",
  filterValues: { order: "desc", liveStatus: "all" },
  sortedListStatus: {
    isAscending: false,
    sortedByDateCreated: false,
    sortedByLastAccess: true,
    sortedByTitle: false,
  },
};

export const initialState = {
  currentSiteName: null,
  currentSitePages: [],
  sites: [],
  recentSites: [],
  sitesByLang: [],
  currentSiteInfo: null,
  currentFilter: "unique-pages",
  totalItems: 0,
  sitesTotalItems: 0,
  currentSiteLanguages: [],
  savedSiteInfo: null,
  currentSiteErrorPages: [],
  contentFilters: null,
  config,
};

export function reducer(state = initialState, action: any): ISitesState {
  switch (action.type) {
    case SET_FILTER:
    case SET_SITES:
    case SET_RECENT_SITES:
    case SET_SITES_TOTAL_ITEMS:
    case SET_SITES_BY_LANG:
    case SET_CURRENT_SITE_INFO:
    case SET_CURRENT_SITE_PAGES:
    case SET_TOTAL_ITEMS:
    case SET_CURRENT_SITE_LANGUAGES:
    case SET_INITIAL_VALUES:
    case SET_SAVED_SITE_INFO:
    case SET_CURRENT_SITE_ERROR_PAGES:
    case SET_CONTENT_FILTERS:
    case SET_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as sitesInitialState, reducer as sitesReducer };
