import React from "react";
import { Icon } from "@ax/components";

import * as S from "./style";

const Tag = (props: ITagProps): JSX.Element => {
  const { type, text, color, icon, rounded = true, className, onDeleteAction } = props;

  const handleClick = () => {
    if (onDeleteAction) {
      onDeleteAction();
    }
  };

  const deleteIcon = onDeleteAction ? (
    <S.IconWrapper onClick={handleClick} data-testid="delete-icon-wrapper">
      <Icon name="close" size="16" />
    </S.IconWrapper>
  ) : null;

  switch (type) {
    case "status":
      return (
        <S.TagStatus className={className} data-testid="tag-status">
          <S.Bullet color={color} />
          {text}
        </S.TagStatus>
      );
    case "square":
      return (
        <S.TagSquare color={color} className={className} data-testid="tag-square">
          {icon && (
            <S.IconTag>
              <Icon name={icon} size="16" />
            </S.IconTag>
          )}
          <div>{text}</div>
        </S.TagSquare>
      );
    default:
      return (
        <S.TagFixed color={color} rounded={rounded} className={className} data-testid="tag-fixed">
          <S.TagText>
            <S.Title data-testid="tag-fixed-title">{text}</S.Title>
            {deleteIcon}
          </S.TagText>
        </S.TagFixed>
      );
  }
};

export interface ITagProps {
  type?: "status" | "fixed" | "interactive" | "square" | undefined;
  text: string;
  color?: string;
  rounded?: boolean;
  icon?: string;
  onDeleteAction?: () => void;
  className?: string;
}

export default Tag;
