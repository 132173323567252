import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { ICheck, IGetSitesParams, IQueryValue, IRootState, ISettingsForm, ISite, ISiteListConfig } from "@ax/types";
import { IError } from "@ax/containers/App/reducer";
import { useBulkSelection, useModal, usePermission } from "@ax/hooks";
import {
  MainWrapper,
  Modal,
  ErrorToast,
  Icon,
  IconAction,
  TableList,
  EmptyState,
  Pagination,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";

import { useFilterQuery, useSortedListStatus, useIsMount } from "./hooks";
import { filterByStatus, getSortedListStatus } from "./utils";

import SiteModal from "./SiteModal";
import RecentSiteItem from "./RecentSiteItem";
import GridHeaderFilter from "./GridView/GridHeaderFilter";
import GridSiteItem from "./GridView/GridSiteItem";
import BulkHeader from "./ListView/BulkHeader";
import ListSiteItem from "./ListView/ListSiteItem";

import * as S from "./style";

const SitesList = (props: ISitesListProps): JSX.Element => {
  const {
    token,
    sites,
    recentSites,
    totalItems,
    saveSettings,
    setHistoryPush,
    getSites,
    publishSitesBulk,
    unpublishSitesBulk,
    error,
    config,
    setListConfig,
    hasAnimation,
    setHasAnimation,
  } = props;

  const isMount = useIsMount();
  const initialState = {
    name: "",
    defaultLanguage: null,
    path: "",
    domain: null,
  };

  const itemsPerPage = 30;
  const firstPage = 1;
  const [sitesIds, setsitesIds] = useState<number[]>([]);

  const [page, setPage] = useState(firstPage);
  const [form, setForm] = useState(initialState);
  const currentConfig = localStorage.getItem("sitesConfig")
    ? JSON.parse(localStorage.getItem("sitesConfig") || "{}")
    : config;
  const [isRecentSitesListDisplayed, setIsRecentSitesListDisplayed] = useState<boolean>(
    currentConfig.displayRecentSites
  );
  const [displayMode, setDisplayMode] = useState<string | "list" | "grid">(currentConfig.mode);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const tableRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  const allowedToCreateSite = usePermission("general.createSite");

  const pagination = {
    setPage,
    itemsPerPage,
    totalItems,
    currPage: page,
  };

  const { isOpen, toggleModal } = useModal();

  const openModal = () => toggleModal();
  const rightButtonProps = allowedToCreateSite
    ? {
        label: "New",
        action: openModal,
      }
    : undefined;

  const { setSortedListStatus } = useSortedListStatus();
  const { setFiltersSelection, resetFilterQuery, filterValues, filterQuery } = useFilterQuery(
    currentConfig.filterValues
  );

  const getParams = useCallback((): IGetSitesParams => {
    const query = searchQuery.length ? `&query=${searchQuery}` : "";
    return {
      pagination: true,
      language: undefined,
      recentSitesNumber: 7,
      page,
      itemsPerPage,
      filterQuery: filterQuery,
      searchQuery: query,
    };
  }, [filterQuery, searchQuery, page]);

  useEffect(() => {
    if (token) {
      const params = getParams();
      if (isMount) {
        getSites({ ...params, token });
      } else {
        getSites(params);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, getParams]);

  useEffect(() => {
    if (sites.length > 0) {
      const currentSitesId = sites?.map((site: ISite) => site.id);
      setsitesIds(currentSitesId);
    }
  }, [sites]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
      const updatedConfig = { ...currentConfig, filterValues };
      setListConfig(updatedConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchQuery, filterValues]);

  useEffect(() => {
    const errorCode = (error && error.code) || undefined;
    if (errorCode === 400 && errorRef.current) {
      errorRef.current.scrollIntoView();
    }
  }, [error]);

  const saveSiteSettings = async () => {
    const result = await saveSettings(form);
    if (result) {
      const contentPath = "/sites/settings/globals";
      setHistoryPush(contentPath, false);
    }
  };

  const mandatoryKeysLength = 4;
  const isSubmitDisabled =
    Object.values(form).filter((el: any) => (typeof el === "string" ? el.trim().length : el)).length !==
    mandatoryKeysLength;

  const mainAction = { title: "Create site", onClick: saveSiteSettings, disabled: isSubmitDisabled };
  const secondaryAction = { title: "Cancel", onClick: toggleModal };

  const toggleRecentSites = () => {
    const updatedConfig = { ...config, displayRecentSites: !isRecentSitesListDisplayed };
    setListConfig(updatedConfig);
    setIsRecentSitesListDisplayed(!isRecentSitesListDisplayed);
  };

  const changeDisplayMode = (mode: "grid" | "list") => {
    const updatedConfig = { ...config, mode };
    setListConfig(updatedConfig);
    setDisplayMode(mode);
  };

  const sortItems = (orderPointer: IQueryValue[], isAscending: boolean) => {
    setPage(firstPage);
    const orderString = orderPointer[0].value.toString();
    const sortedState = getSortedListStatus(orderString, isAscending);
    setSortedListStatus(sortedState);
    const pointer = orderString === "title" ? [{ value: "name", label: "value" }] : orderPointer;
    setFiltersSelection("order", pointer, isAscending);
    const updatedConfig = { ...currentConfig, filter: filterQuery, sortedListStatus: sortedState };
    setListConfig(updatedConfig);
  };

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) => {
    setPage(firstPage);
    setFiltersSelection(filterPointer, filtersSelected);
    const updatedConfig = { ...currentConfig, filter: filterQuery };
    setListConfig(updatedConfig);
  };

  const RecentSitesHeader = () => (
    <S.SectionHeader data-testid="recent-sites-header" isRecentSites={true}>
      <S.Title data-testid="recent-sites-title" isActive={isRecentSitesListDisplayed}>
        Recent sites
      </S.Title>
      <S.CollapseButton data-testid="recent-sites-collapse-button" onClick={toggleRecentSites}>
        {isRecentSitesListDisplayed ? (
          <>
            <S.Label data-testid="recent-sites-hide-label">Hide recent sites </S.Label> <Icon name="UpArrow" />
          </>
        ) : (
          <>
            <S.Label data-testid="recent-sites-show-label">Show recent sites </S.Label> <Icon name="DownArrow" />
          </>
        )}
      </S.CollapseButton>
    </S.SectionHeader>
  );

  const RecentSitesList = () => {
    return (
      <S.RecentSites data-testid="recent-sites-list" fullWidth={recentSites.length >= 5}>
        <RecentSitesHeader />
        <S.RecentSitesItemsWrapper data-testid="recent-sites-items-wrapper" isHidden={!isRecentSitesListDisplayed}>
          {recentSites.map((site: ISite, i: number) => (
            <RecentSiteItem key={i} site={site} />
          ))}
        </S.RecentSitesItemsWrapper>
      </S.RecentSites>
    );
  };

  const AllSitesHeader = () => (
    <S.SectionHeader data-testid="all-sites-header">
      <S.Title isActive={true} data-testid="all-sites-title">
        All sites
      </S.Title>
      <S.HeaderIconsWrapper data-testid="all-sites-header-icons">
        {displayMode === "grid" ? (
          <S.FilterSelect data-testid="all-sites-grid-filter">
            <S.FilterSelectLabel data-testid="all-sites-grid-filter-label">Sort by:</S.FilterSelectLabel>{" "}
            <GridHeaderFilter sortItems={sortItems} sortedState={currentConfig.sortedListStatus} />{" "}
          </S.FilterSelect>
        ) : null}
        <IconAction icon="Grid2" onClick={() => changeDisplayMode("grid")} active={displayMode === "grid"} />
        <IconAction icon="BulletList" onClick={() => changeDisplayMode("list")} active={displayMode === "list"} />
      </S.HeaderIconsWrapper>
    </S.SectionHeader>
  );

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  } = useBulkSelection(sitesIds);

  const bulkFilter = (bulkSelection: number[]) => filterByStatus(bulkSelection, sites);

  const handleAddToBulk = (item: ICheck) => addToBulkSelection(item, bulkFilter);

  const handleSelectAll = () => selectAllItems(bulkFilter);

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const bulkPublishAction = async (isPublish: boolean) => {
    const { notPublished, published } = selectedItems;
    const params = getParams();

    if (notPublished.length > 0 && isPublish) {
      await publishSitesBulk(notPublished, params);
    }
    if (published.length > 0 && !isPublish) {
      await unpublishSitesBulk(published, params);
    }
    unselectAllItems();
  };

  const bulkPublish = () => bulkPublishAction(true);
  const bulkUnpublish = () => bulkPublishAction(false);

  const Header = (
    <BulkHeader
      showBulk={areItemsSelected(sitesIds)}
      bulkPublish={bulkPublish}
      bulkUnpublish={bulkUnpublish}
      checkState={checkState}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      sortItems={sortItems}
      sortedListStatus={currentConfig.sortedListStatus}
      filterItems={filterItems}
      filterValues={currentConfig.filterValues}
    />
  );

  const mappedSites =
    sites.length > 0 ? (
      sites.map((site: ISite) => {
        const isItemSelected = isSelected(site.id);
        return displayMode === "grid" ? (
          <GridSiteItem key={site.id} site={site} getParams={getParams} />
        ) : (
          <ListSiteItem
            key={site.id}
            site={site}
            isSelected={isItemSelected}
            onCheck={handleAddToBulk}
            getParams={getParams}
          />
        );
      })
    ) : (
      <S.EmptyStateWrapper data-testid="empty-state">
        <EmptyState icon="search" message="We couldn't find what you are looking for. Please, try another search." />
      </S.EmptyStateWrapper>
    );

  const GridList = () => {
    const showPagination = totalItems > itemsPerPage;
    return (
      <>
        <S.SearchTagsGrid>
          <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
        </S.SearchTagsGrid>
        <S.GridList data-testid="sites-grid-list" isEmpty={sites.length === 0} fullWidth={sites.length >= 5}>
          {mappedSites}
        </S.GridList>
        {showPagination && (
          <S.PaginationWrapper>
            <Pagination totalItems={totalItems} setPage={setPage} itemsPerPage={itemsPerPage} currPage={page} />
          </S.PaginationWrapper>
        )}
      </>
    );
  };

  const filterLabels = {
    liveStatus: "Live",
  };

  const ListTable = () => (
    <TableList tableHeader={Header} pagination={pagination} hasFixedHeader={true} tableRef={tableRef}>
      <>
        <S.SearchTags>
          <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
          <FilterTagsBar
            filters={filterValues}
            setFilters={setFiltersSelection}
            resetFilters={resetFilterQuery}
            labels={filterLabels}
          />
        </S.SearchTags>
        {mappedSites}
      </>
    </TableList>
  );

  const AllSitesList = () => (
    <S.AllSitesListWrapper className={hasAnimation ? "animate" : ""} onAnimationEnd={handleAnimationEnd}>
      {displayMode === "grid" ? <GridList /> : <ListTable />}
    </S.AllSitesListWrapper>
  );

  const handleAnimationEnd = () => setHasAnimation(false);

  return (
    <MainWrapper
      title="Sites"
      rightButton={rightButtonProps}
      searchAction={setSearchQuery}
      hasAnimation={hasAnimation}
      searchValue={searchQuery}
    >
      <ErrorToast ref={errorRef} />
      <S.SitesListWrapper className={hasAnimation ? "animate" : ""}>
        {recentSites?.length > 0 ? <RecentSitesList /> : null}
        <AllSitesHeader />
        <AllSitesList />
      </S.SitesListWrapper>
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        size="M"
        title="New Site"
        mainAction={mainAction}
        secondaryAction={secondaryAction}
      >
        <SiteModal form={form} setForm={setForm} />
      </Modal>
    </MainWrapper>
  );
};

interface ISitesProps {
  sites: ISite[];
  recentSites: ISite[];
  totalItems: number;
  token: string;
  error: IError;
  config: ISiteListConfig;
  hasAnimation: boolean;
}

const mapStateToProps = (state: IRootState) => ({
  totalItems: state.sites.sitesTotalItems,
  token: state.app.token,
  sites: state.sites.sites,
  recentSites: state.sites.recentSites,
  error: state.app.error,
  config: state.sites.config,
  hasAnimation: state.app.hasAnimation,
});

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): void;
  saveSettings(form: ISettingsForm): Promise<boolean>;
  getSites(params: IGetSitesParams): Promise<void>;
  publishSitesBulk(ids: number[], params?: IGetSitesParams): Promise<void>;
  unpublishSitesBulk(ids: number[], params?: IGetSitesParams): Promise<void>;
  setListConfig(config: ISiteListConfig): void;
  setHasAnimation(hasAnimation: boolean): void;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  saveSettings: sitesActions.saveSettings,
  getSites: sitesActions.getSites,
  publishSitesBulk: sitesActions.publishSitesBulk,
  unpublishSitesBulk: sitesActions.unpublishSitesBulk,
  setListConfig: sitesActions.setListConfig,
  setHasAnimation: appActions.setHasAnimation,
};

export type ISitesListProps = ISitesProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(SitesList);
