import styled from "styled-components";

const Wrapper = styled.div``;

const Label = styled.label<{ disabled: boolean | undefined; inversed: boolean; hasText: boolean }>`
  ${(p) => p.theme.textStyle.fieldContent};
  position: relative;
  display: ${(p) => (p.hasText ? "block" : "inline")};
  padding-left: calc(${(p) => p.theme.spacing.s} + ${(p) => p.theme.spacing.xs});
  cursor: pointer;
  user-select: none;
  color: ${(p) =>
    p.disabled
      ? p.theme.color.interactiveDisabled
      : p.inversed
      ? p.theme.color.textHighEmphasisInverse
      : p.theme.color.textMediumEmphasis};
`;

const CheckMark = styled.span<{
  checked: boolean | undefined;
  error: boolean | undefined;
  indeterminate: boolean | undefined;
  inversed: boolean;
}>`
  position: absolute;
  top: 2px;
  left: 0;
  height: ${(p) => p.theme.spacing.s};
  width: ${(p) => p.theme.spacing.s};
  background-color: ${(p) =>
    p.error
      ? p.theme.color.error
      : p.checked || p.indeterminate
      ? p.theme.color.interactive01
      : p.inversed
      ? "transparent"
      : p.theme.color.interactiveBackground};
  border: 2px solid
    ${(p) =>
      p.error
        ? p.theme.color.error
        : p.checked || p.indeterminate
        ? p.theme.color.interactive01
        : p.inversed
        ? p.theme.color.interactiveInverse
        : p.theme.color.interactiveInactive};
  border-radius: 1.5px;
  :after {
    content: "";
    position: absolute;
    display: ${(p) => (p.checked || p.indeterminate ? `blocked` : `none`)};
    left: 4px;
    top: 0;
    width: 4px;
    height: 10px;
    border: solid #ffffff;
    color: white;
    border-width: ${(p) => (p.indeterminate ? "0 2px 0 0" : "0 2px 2px 0")};
    transform: ${(p) => (p.indeterminate ? "rotate(90deg)" : "rotate(45deg)")};
  }

  &.light {
    border-color: ${(p) =>
      p.error
        ? p.theme.color.error
        : p.checked || p.indeterminate
        ? p.theme.color.interactive01
        : p.theme.color.textLowEmphasis};
    &:hover {
      border-color: ${(p) =>
        p.error
          ? p.theme.color.error
          : p.checked || p.indeterminate
          ? p.theme.color.interactive01
          : p.theme.color.interactiveInactive};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :disabled ~ ${CheckMark} {
    background-color: ${(p) => (p.checked ? p.theme.color.interactiveDisabled : p.theme.color.interactiveBackground)};
    border: 2px solid ${(p) => p.theme.color.interactiveDisabled};
  }
`;

const IconLabelWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  padding-right: ${(p) => p.theme.spacing.xs};
`;

export { Wrapper, Label, CheckMark, Input, IconLabelWrapper, IconWrapper };
