import React from "react";

import { useModal } from "@ax/hooks";
import { ISite } from "@ax/types";
import { formatBytes, getFormattedDateWithTimezone } from "@ax/helpers";
import { Icon, Modal, TextField, FileGallery } from "@ax/components";

import * as S from "./style";

const FileField = (props: IFileFieldProps): JSX.Element => {
  const { disabled, value, site, onChange } = props;

  const { isOpen, toggleModal } = useModal(false);

  const validFormats = ["pdf", "doc", "docx", "xls", "xlsx", "zip", "csv"];

  const handleClick = () => {
    if (!disabled) {
      toggleModal();
    }
  };

  const addFile = (newFile: any) => {
    onChange(newFile);
    toggleModal();
  };

  const handleOnClickUrl = () => {
    if (value && value.url) {
      const win = window.open(value.url, "_blank");
      if (win) {
        win.focus();
      }
    }
  };

  const removeFile = () => {
    onChange(null);
  };

  const replaceFile = () => {
    toggleModal();
  };

  const menuOptions = [
    {
      label: "replace",
      icon: "change",
      action: replaceFile,
      disabled,
    },
    {
      label: "delete",
      icon: "delete",
      action: removeFile,
      disabled,
    },
  ];

  const fileName = value && value.url ? value.url.split("/").pop() : "File title";
  const fileDate = value && value.uploadDate ? getFormattedDateWithTimezone(value.uploadDate, "d MMM Y") : "--";
  const fileSize = value && value.sizeBytes ? formatBytes(value.sizeBytes) : "-- KB";

  return (
    <>
      <S.FileDataWrapper data-testid="file-data-wrapper">
        <S.FileName>{fileName}</S.FileName>
        <S.FileData>
          <div>Uploaded: {fileDate}</div>
          <div>{fileSize}</div>
        </S.FileData>
      </S.FileDataWrapper>
      {value && value.url && (
        <S.TextFieldWrapper data-testid="text-field-wrapper">
          <TextField
            name="url"
            value={value.url}
            onChange={() => null}
            readonly={true}
            icon="openOutside"
            onClickIcon={handleOnClickUrl}
          />
        </S.TextFieldWrapper>
      )}
      {!value && (
        <>
          <S.Field onClick={handleClick} data-testid="field-icon-wrapper" disabled={disabled}>
            <S.IconWrapper>
              <Icon name="File" size="24" />
            </S.IconWrapper>
          </S.Field>
          <S.HelpText>Valid formats: {validFormats.join(", ")}. Max. size: 50MB</S.HelpText>
        </>
      )}
      {value && (
        <S.Component>
          <S.ComponentIconWrapper>
            <Icon name="File" />
          </S.ComponentIconWrapper>
          <S.Label>{fileName}</S.Label>
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.Component>
      )}
      <Modal isOpen={isOpen} hide={toggleModal} size="XL" title="Select file">
        {isOpen && <FileGallery validFormats={validFormats} addFile={addFile} toggleModal={toggleModal} site={site} />}
      </Modal>
    </>
  );
};

export interface IFileFieldProps {
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  site: ISite | null;
}

export default FileField;
