import {
  SET_BREADCRUMB,
  SET_SCHEMA,
  SET_TAB,
  SET_SELECTED_EDITOR_ID,
  SET_EDITOR_CONTENT,
  SET_TEMPLATE,
  SET_SELECTED_CONTENT,
  SET_SELECTED_PARENT,
  SET_CURRENT_PAGE_ID,
  SET_CURRENT_PAGE_STATUS,
  SET_CURRENT_PAGE_NAME,
  SET_CURRENT_PAGE_LANGUAGES,
  SET_IS_NEW_TRANSLATION,
  SET_TEMPLATE_CONFIG,
  SET_ERRORS,
  SET_VALIDATED,
  SET_SITE_PAGE_ID,
  SET_USER_EDITING,
  SET_LAST_ELEMENT_ADDED_ID,
  SET_COPY_MODULE,
  SET_IS_IA_TRANSLATED,
  SET_LAST_TIMEOUT,
} from "./constants";
import { IBreadcrumbItem, ISchema, IPage, IErrorItem, IUserEditing } from "@ax/types";

export interface ISetEditorContent {
  type: typeof SET_EDITOR_CONTENT;
  payload: { editorContent: IPage | Record<string, unknown> };
}

export interface ISetTemplate {
  type: typeof SET_TEMPLATE;
  payload: { template: string };
}

export interface ISetBreadcrumb {
  type: typeof SET_BREADCRUMB;
  payload: { breadcrumb: IBreadcrumbItem[] };
}

export interface ISetSchema {
  type: typeof SET_SCHEMA;
  payload: { schema: ISchema | Record<string, unknown> };
}

export interface ISetSelectedContent {
  type: typeof SET_SELECTED_CONTENT;
  payload: { selectedContent: any };
}

export interface ISetSelectedParent {
  type: typeof SET_SELECTED_PARENT;
  payload: { selectedParent: any };
}

export interface ISetTab {
  type: typeof SET_TAB;
  payload: { tab: string };
}

export interface ISetSelectedEditorID {
  type: typeof SET_SELECTED_EDITOR_ID;
  payload: { selectedEditorID: number };
}

export interface ISetCurrentPageIDAction {
  type: typeof SET_CURRENT_PAGE_ID;
  payload: { currentPageID: number | null };
}

export interface ISetCurrentPageStatusAction {
  type: typeof SET_CURRENT_PAGE_STATUS;
  payload: { currentPageStatus: string | null };
}

export interface ISetCurrentPageNameAction {
  type: typeof SET_CURRENT_PAGE_NAME;
  payload: { currentPageName: string };
}

export interface ISetCurrentPageLanguagesAction {
  type: typeof SET_CURRENT_PAGE_LANGUAGES;
  payload: { currentPageLanguages: any[] };
}

export interface ISetIsNewTranslation {
  type: typeof SET_IS_NEW_TRANSLATION;
  payload: { isNewTranslation: boolean };
}

export interface ISetTemplateConfig {
  type: typeof SET_TEMPLATE_CONFIG;
  payload: { templateConfig: any };
}

export interface ISetErrors {
  type: typeof SET_ERRORS;
  payload: { errors: IErrorItem[] };
}

export interface ISetValidated {
  type: typeof SET_VALIDATED;
  payload: { validated: boolean };
}

export interface ISetSitePageID {
  type: typeof SET_SITE_PAGE_ID;
  payload: { sitePageID: number | null };
}

export interface ISetUserEditing {
  type: typeof SET_USER_EDITING;
  payload: { userEditing: IUserEditing | null };
}

export interface ISetLastElementAddedId {
  type: typeof SET_LAST_ELEMENT_ADDED_ID;
  payload: { lastElementAddedId: null | number };
}

export interface ISetCopyModule {
  type: typeof SET_COPY_MODULE;
  payload: { moduleCopy: { date: Date; elements: Record<string, unknown>[] } | null };
}

export interface ISetIsIATranslated {
  type: typeof SET_IS_IA_TRANSLATED;
  payload: { isIATranslated: boolean };
}

export interface ISetLastTimeout {
  type: typeof SET_LAST_TIMEOUT;
  payload: { lastTimeout: NodeJS.Timeout | null };
}

export interface IFieldProps {
  id: number;
  key: string;
  value: any;
}

export enum pageStatus {
  PUBLISHED = "active",
  OFFLINE = "offline",
  UPLOAD_PENDING = "upload-pending",
  OFFLINE_PENDING = "offline-pending",
  MODIFIED = "modified",
}

type EditorActionsCreators = ISetEditorContent;
type ConfigPanelActionsCreators = ISetBreadcrumb & ISetSchema & ISetTab;
type PageActionCreators = ISetCurrentPageStatusAction & ISetCurrentPageIDAction & ISetCurrentPageNameAction;
export type PageEditorActionsCreators = EditorActionsCreators & ConfigPanelActionsCreators & PageActionCreators;
