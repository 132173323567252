import { useState } from "react";
import { IQueryValue, IUsersQueryValues } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean } = {
    isAscending: false,
    sortedByName: false,
    sortedByDateCreated: true,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = () => {
  const initialQueryValues: Record<string, IQueryValue[]> = {
    order: [],
    filterSites: [{ value: "all", label: "All" }],
    filterRoles: [{ value: "all", label: "All" }],
  };

  const [query, setQuery] = useState(initialQueryValues);
  const [currentFilterQuery, setCurrentFilterQuery] = useState("");

  const setFilterQuery = (filterValues: IUsersQueryValues) => {
    const { order, filterSites, filterRoles } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: IQueryValue[]): string => {
      const stringValues = Array.isArray(values) && values.length
        ? values.map((value) => (value.value !== "all" ? value.value : "")).join(",")
        : "";

      return !stringValues.length
        ? filterQuery
        : filterQuery.length
        ? filterQuery.concat(`&${pointer}=${stringValues}`)
        : `?${pointer}=${stringValues}`;
    };

    const isNotInitialValue = (pointer: keyof IUsersQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);
    if (isNotInitialValue("filterSites")) filterQuery = currentQuery("filterSites", filterSites);
    if (isNotInitialValue("filterRoles")) filterQuery = currentQuery("filterRoles", filterRoles);

    setCurrentFilterQuery(filterQuery);
  };

  const setFiltersSelection = (pointer: string, filter: IQueryValue[], isAscendent?: boolean) => {
    const { order, filterSites, filterRoles } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? [{ value: `${filter[0].value}-${orderMethod}`, label: filter[0].label }] : order,
      filterSites: pointer === "filterSites" ? filter : filterSites,
      filterRoles: pointer === "filterRoles" ? filter : filterRoles,
    };

    setQuery(filterValues);
    setFilterQuery(filterValues);
  };

  const resetFilterQuery = () => {
    setQuery(initialQueryValues);
    setCurrentFilterQuery("");
  }

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: query,
    filterQuery: currentFilterQuery,
  };
};

export { useSortedListStatus, useFilterQuery };
