import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  IRootState,
  IStructuredData,
  IStructuredDataContent,
  IGetStructuredDataParams,
  IDataPack,
  IEmptyStateProps,
  ILanguage,
  IStructuredDataFilter,
  IStructuredDataValue,
  IGetGlobalPagesParams,
  IPage,
  ICheck,
  IColumn,
  IStructuredDataQueryValues,
  ISite,
  IQueryValue,
} from "@ax/types";
import {
  ISetCurrentPageIDAction,
  ISetCurrentPageStatusAction,
  ISetCurrentPageNameAction,
  pageStatus,
} from "@ax/containers/PageEditor/interfaces";
import { useBulkSelection, useModal, useToast, useCategoryColors, usePermission } from "@ax/hooks";
import { appActions } from "@ax/containers/App";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { sitesActions } from "@ax/containers/Sites";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { analyticsActions } from "@ax/containers/Analytics";
import {
  MainWrapper,
  TableList,
  ErrorToast,
  Toast,
  Notification,
  EmptyState,
  Modal,
  SearchTagsBar,
  FilterTagsBar,
} from "@ax/components";

import StructuredDataItem from "./StructuredDataItem";
import BulkHeader from "./BulkHeader";
import ContentFilters from "./ContentFilters";
import OptionTable from "./OptionTable";
import {
  getSelectedValue,
  getSortedListStatus,
  getOptionFilters,
  getOptionValues,
  filterByStatus,
  getAllLangPagesIds,
} from "./utils";
import { useSortedListStatus, useFilterQuery } from "./hooks";
import { DeleteModal } from "./atoms";
import GlobalPageItem from "./GlobalPageItem";

import * as S from "./style";

const StructuredDataList = (props: IProps): JSX.Element => {
  const {
    setHistoryPush,
    resetForm,
    currentStructuredData,
    currentDataContent,
    getStructuredDataContents,
    lang,
    globalLangs,
    setLanguage,
    totalItems,
    structuredData,
    currentSiteID,
    setSelectedStructuredData,
    deleteDataContent,
    restoreDataContent,
    activatedDataPacks,
    setDataStatus,
    filter,
    setFilter,
    isLoading,
    setCurrentPageID,
    addTemplate,
    template,
    setCurrentPageStatus,
    setCurrentPageName,
    getGlobalPages,
    currentSitePages,
    updatePageStatus,
    deleteBulk,
    restorePage,
    resetPageEditor,
    getAnalytics,
    setCurrentDataID,
    resetCurrentSiteErrorPages,
    currentSiteErrorPages,
    skipReviewOnPublish,
    setContentFilters,
    contentFilters,
    checkUserSession,
    sites,
  } = props;

  const itemsPerPage = 50;
  const firstPage = 1;
  const [page, setPage] = useState(firstPage);
  const lastPage = Math.ceil(totalItems / itemsPerPage);
  const isLastItem = page === lastPage && currentSitePages.length === 1;

  const [structuredDataType, setStructuredDataType] = useState("all");

  const [isScrolling, setIsScrolling] = useState(false);
  const [deletedItem, setDeletedItem] = useState<number | number[] | null>(null);
  const { isVisible: isDataToast, toggleToast: toggleDataToast, setIsVisible: setIsDataToast } = useToast();
  const { isVisible: isPageToast, toggleToast: togglePageToast, setIsVisible: setIsPageToast } = useToast();
  const tableRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [emptyStateProps, setEmptyStateProps] = useState<IEmptyStateProps>({});
  const { isOpen: isNewOpen, toggleModal: toggleNewModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { sortedListStatus, setSortedListStatus } = useSortedListStatus();
  const {
    setFiltersSelection,
    filterValues,
    resetFilterQuery,
    query: currentFilterQuery,
  } = useFilterQuery(currentStructuredData, contentFilters);
  const history = useHistory();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const [arePagesTranslated, setArePagesTranslated] = useState(false);
  const { state: locationState } = useLocation<{ isFromEditor: boolean }>();
  const { categoryColors, addCategoryColors } = useCategoryColors();
  const [notification, setNotification] = useState<{
    text: string;
    subErrors?: { id: number; error: string }[];
  } | null>(null);

  const allowedToCreatePages = usePermission("global.globalData.createAllGlobalData");

  const scope = currentSiteID ? "site" : "global";

  const isDataEditable = filter === "all-pages" || !currentStructuredData || currentStructuredData.editable;
  const isDataTranslatable = currentStructuredData && currentStructuredData.translate;
  const isAllPages = filter === "all-pages";
  const isStructuredDataFromPage = !!currentStructuredData?.fromPage || isAllPages;
  const dataIds = isStructuredDataFromPage
    ? currentSitePages.map((page: IPage) => page.id)
    : currentDataContent.map((data: IStructuredDataContent) => data.id);

  const categoryColumns =
    currentStructuredData && currentStructuredData.schema
      ? currentStructuredData.schema.fields.filter((field: any) => field.showList)
      : [];

  const defaultColumns: Record<string, IColumn> = {
    live: { title: "Live", show: true },
    status: { title: "Status", show: true },
    translation: { title: "Trans.", show: true },
    ...((isStructuredDataFromPage || currentStructuredData === null) && { seo: { title: "SEO", show: true } }),
  };

  const extraColumns = categoryColumns.reduce((acc: Record<string, IColumn>, cur: any) => {
    acc[cur.key] = { title: cur.title, show: !isStructuredDataFromPage };
    return acc;
  }, {});

  const allColumns = { type: { title: "Types", show: true }, ...extraColumns, ...defaultColumns };
  const filterColumns = {
    ...(isStructuredDataFromPage && { site: { title: "Site", show: true } }),
    ...extraColumns,
    ...defaultColumns,
  };

  const initialColumns = isAllPages ? allColumns : filterColumns;

  const [columnsState, setColumnsState] = useState<Record<string, any>>({ all: allColumns });

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  } = useBulkSelection(dataIds);

  const getParams = useCallback(() => {
    const params = {
      page,
      itemsPerPage,
      pagination: true,
      deleted: false,
      include_draft: true,
      query: searchQuery,
      format: "list",
      relatedFields: true,
    };

    return params;
  }, [page, searchQuery]);

  const getStructuredData = useCallback(
    async (id: string, filterQuery?: string) => {
      const params: IGetStructuredDataParams = { ...getParams(), dataID: null };
      params.dataID = id;
      params.filterQuery = filterQuery;
      await getStructuredDataContents(params);
    },
    [getParams, getStructuredDataContents]
  );

  const handleGetGlobalPages = async () => {
    const params: IGetGlobalPagesParams = getParams();
    params.filterStructuredData = isStructuredDataFromPage && !isAllPages && filter;
    await getGlobalPages(params, currentFilterQuery);
  };

  const handleGetStructuredData = () => getStructuredData(filter, currentFilterQuery);

  const handleGetData = () => {
    isStructuredDataFromPage ? handleGetGlobalPages() : handleGetStructuredData();
  };

  const resetFilter = () => {
    if (history.action !== "POP" && (!locationState || locationState.isFromEditor !== true)) {
      setFilter("all-pages");
    }
  };

  const changeColumnsState = (updatedColumns: any) => {
    setColumnsState((state) => ({ ...state, [structuredDataType]: updatedColumns }));
  };

  useLayoutEffect(() => {
    resetFilter();
    setIsFirstRender(false);
    resetPageEditor();
    resetCurrentSiteErrorPages();
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const isGlobalData = structuredData.global.some((data) => data.id === currentStructuredData?.id);
    const type = currentStructuredData && isGlobalData ? currentStructuredData.id : "all";
    setStructuredDataType(type);

    filterItems("types", [{ value: "all", label: "All" }]);
    if (filter === "all-pages" || !isStructuredDataFromPage) {
      filterItems("filterSites", [{ value: "all", label: "All" }]);
      setColumnsState({ all: allColumns });
    }
    unselectAllItems();
    if (filter !== "all-pages" && currentStructuredData) {
      setColumnsState((state) => ({
        [currentStructuredData.id]: {
          site: { title: "Site", show: true },
          ...extraColumns,
          ...initialColumns,
        },
        ...state,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStructuredData]);

  useEffect(() => {
    if (!isFirstRender) {
      handleGetData();
    }
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang.locale, page, searchQuery, filterValues]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataContent]);

  const resetFilterValues = () => resetFilterQuery();

  useEffect(() => {
    if (!isFirstRender && !isLoading) {
      const emptyState: IEmptyStateProps = {};
      const isSearching = searchQuery.length > 0;
      if (isSearching) {
        emptyState.icon = "search";
        emptyState.title = "Oh! No Results Found";
        emptyState.message = "We couldn’t find what you are looking for. Please, try another search.";
      } else {
        emptyState.message = isAllPages
          ? "You don’t have pages with this content type yet."
          : allowedToCreatePages && isDataEditable
          ? "To start using pages in your site, create as many pages as you need."
          : undefined;
        emptyState.button = isAllPages
          ? "View all content"
          : allowedToCreatePages && isDataEditable
          ? "Create the first page"
          : undefined;
        emptyState.action = isAllPages ? resetFilterValues : isDataEditable ? addNewAction : undefined;
      }
      const isEmpty = isStructuredDataFromPage ? !currentSitePages.length : !currentDataContent.length;
      setIsEmpty(isEmpty);
      setEmptyStateProps(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, currentSitePages, currentDataContent]);

  useEffect(() => {
    checkUserSession();
    getAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContentFilters(filterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const handleClick = (isFromPage?: boolean, globalPage?: IPage, forceLive?: boolean) => {
    if (isFromPage && globalPage) {
      const pageID = globalPage.haveDraftPage && !forceLive ? globalPage.haveDraftPage : globalPage.id;
      setCurrentPageID(pageID);
    }
    const path = isFromPage ? "data/pages/editor" : `data/${currentStructuredData?.id}/editor`;
    setHistoryPush(path, isFromPage);
  };

  const handleMenuClick = async (dataID: string) => {
    setPage(firstPage);
    setSelectedStructuredData(dataID, scope);
  };

  const createNewData = () => {
    setCurrentDataID(null);
    resetForm(true);
    handleClick();
  };

  const bulkDelete = async () => {
    toggleDeleteModal();

    const idsToBeDeleted = getAllLangPagesIds(currentSitePages, selectedItems, deleteAllVersions);
    const deleted = isStructuredDataFromPage
      ? await deleteBulk(idsToBeDeleted)
      : await deleteDataContent(idsToBeDeleted);

    if (deleted) {
      setDeletedItem(idsToBeDeleted);
      isStructuredDataFromPage ? togglePageToast() : toggleDataToast();
    }

    const allPageItemsSelected = selectedItems.all.length >= currentSitePages.length;
    const previousPage = page - 1;
    page > 1 && (isLastItem || allPageItemsSelected)
      ? setPage(previousPage)
      : isStructuredDataFromPage && handleGetGlobalPages();
    unselectAllItems();
  };

  const handleBulkDelete = () => {
    const selectedPages = currentSitePages.filter((page) => selectedItems.all.includes(page.id));
    const hasTranslations = selectedPages.some((page) => page.pageLanguages.length > 1);
    setArePagesTranslated(hasTranslations);
    toggleDeleteModal();
  };

  const bulkPublishPage = async (isPublish: boolean) => {
    const { notPublished, published, drafts } = selectedItems;

    if (drafts && drafts.length > 0) {
      const multiplePages = drafts.length > 1;
      const getPage = (id: number) => currentSitePages.find((page) => page.id === id);
      const getPageWarning = (pageId: number) =>
        `The '${getPage(pageId)?.title}' page has a draft version. To publish it, you must do it from the editor.`;
      const text = multiplePages
        ? `There are ${drafts.length} pages that cannot be published.`
        : getPageWarning(drafts[0]);
      const subErrors = multiplePages && drafts.map((draft) => ({ id: draft, error: getPageWarning(draft) }));
      setNotification({ text, ...(subErrors && { subErrors }) });
    }

    const status = isPublish ? pageStatus.UPLOAD_PENDING : pageStatus.OFFLINE_PENDING;

    if (notPublished.length > 0) {
      isPublish
        ? await updatePageStatus(notPublished, status, true)
        : await updatePageStatus(notPublished, pageStatus.OFFLINE, true);
    }

    if (published.length > 0 && !isPublish) {
      await updatePageStatus(published, status, true);
    }

    handleGetGlobalPages();
    unselectAllItems();
  };

  const bulkPublishData = (status: string) => {
    setDataStatus(selectedItems.all, status);
    unselectAllItems();
  };

  const bulkFilter = (bulkSelection: number[]) => filterByStatus(bulkSelection, currentSitePages);

  const handleAddToBulk = (item: ICheck) => addToBulkSelection(item, bulkFilter);

  const bulkPublish = () => (isStructuredDataFromPage ? bulkPublishPage(true) : bulkPublishData("undraft"));

  const bulkUnpublish = () => (isStructuredDataFromPage ? bulkPublishPage(false) : bulkPublishData("draft"));

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems(bulkFilter);

  const onScroll = (e: React.UIEvent<HTMLElement>) => setIsScrolling(e.currentTarget.scrollTop > 0);

  const undoDeleteData = () => {
    if (deletedItem) {
      restoreDataContent(deletedItem);
    }
    setIsDataToast(false);
  };

  const undoDeletePage = async () => {
    setIsPageToast(false);
    if (deletedItem) {
      await restorePage(deletedItem);
      handleGetGlobalPages();
    }
  };

  const sortItems = async (orderPointer: IQueryValue[], isAscending: boolean) => {
    setPage(firstPage);
    const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);

    setFiltersSelection("order", orderPointer, isAscending);
  };

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) => {
    setPage(firstPage);
    if (!isStructuredDataFromPage && filterPointer === "categories") filterPointer = "related";
    setFiltersSelection(filterPointer, filtersSelected);
  };

  const currentDataColumnsState = currentStructuredData ? columnsState[structuredDataType] || [] : columnsState["all"];

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(dataIds)}
      bulkDelete={handleBulkDelete}
      bulkPublish={bulkPublish}
      bulkUnpublish={bulkUnpublish}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      isEditable={isDataEditable}
      isFromPage={isStructuredDataFromPage}
      sortItems={sortItems}
      sortedListStatus={sortedListStatus}
      filterItems={filterItems}
      filterValues={filterValues[structuredDataType]}
      isAllPages={isAllPages}
      categoryColumns={categoryColumns}
      columns={currentDataColumnsState}
      setColumns={changeColumnsState}
    />
  );

  const languageActions = { setLanguage };

  const pagination = {
    setPage,
    itemsPerPage,
    totalItems,
    currPage: page,
  };

  const addNewPage = () => {
    addTemplate(template);
    setCurrentPageID(null);
    setCurrentPageStatus("offline");
    setCurrentPageName("New Global Page");
    const path = "/data/pages/editor/new";
    setHistoryPush(path, true);
  };

  const addNewAction = isAllPages || isStructuredDataFromPage ? toggleNewModal : createNewData;

  const rightButtonProps = allowedToCreatePages
    ? {
        label: "New",
        action: addNewAction,
        disabled: !isDataEditable,
      }
    : undefined;

  const options: { filters: IStructuredDataFilter[]; values: IStructuredDataValue[] } = {
    filters: getOptionFilters(structuredData),
    values: getOptionValues(structuredData),
  };

  const notEditableText =
    "Sorry, this content cannot be edited because it comes from an external source or belongs to a preconfigured system.";

  const languageProps = {
    globalLangs: isAllPages || isDataTranslatable ? globalLangs : null,
    lang: isAllPages || isDataTranslatable ? lang : null,
    languageActions: isAllPages || isDataTranslatable ? languageActions : null,
  };

  const selectedValue = getSelectedValue(options, filter);

  const mapCurrentDataContent = () =>
    currentDataContent &&
    currentDataContent.map((sData: IStructuredDataContent) => {
      const isItemSelected = isSelected(sData.id);
      return (
        <StructuredDataItem
          structuredData={sData}
          key={sData.id}
          handleClick={handleClick}
          languages={globalLangs}
          lang={lang}
          isSelected={isItemSelected}
          onChange={addToBulkSelection}
          toggleToast={toggleDataToast}
          setDeletedItem={setDeletedItem}
          isEditable={isDataEditable}
          activatedDataPacks={activatedDataPacks}
          categoryColumns={categoryColumns}
          columns={columnsState}
          categoryColors={categoryColors}
          addCategoryColors={addCategoryColors}
        />
      );
    });

  const mapGlobalPages = () =>
    currentSitePages &&
    currentSitePages.map((globalPage: IPage) => {
      const isItemSelected = isSelected(globalPage.id);
      const relatedStructuredData = structuredData.global.find(
        (data: IStructuredData) => data.id === globalPage.structuredData
      );
      const isTranslatable = relatedStructuredData?.translate;
      return (
        <GlobalPageItem
          key={globalPage.id}
          handleClick={handleClick}
          languages={globalLangs}
          lang={lang}
          isSelected={isItemSelected}
          onChange={handleAddToBulk}
          isEditable={isDataEditable}
          isTranslatable={isTranslatable}
          isAllPages={isAllPages}
          globalPage={globalPage}
          getGlobalPages={handleGetGlobalPages}
          toggleToast={togglePageToast}
          setDeletedItem={setDeletedItem}
          categoryColumns={categoryColumns}
          columns={currentDataColumnsState}
          categoryColors={categoryColors}
          addCategoryColors={addCategoryColors}
          skipReview={skipReviewOnPublish}
        />
      );
    });

  const deletedCount = !deletedItem ? 0 : Array.isArray(deletedItem) ? deletedItem.length : 1;

  const content = isStructuredDataFromPage ? mapGlobalPages() : mapCurrentDataContent();

  const errorPagesText =
    currentSiteErrorPages.length > 1
      ? "These pages contains some errors, so you can not publish them yet. Please, review the errors on the pages."
      : "This page contains some errors, so you can not publish it yet. Please, review the errors on the page.";

  const labelCategory = isStructuredDataFromPage ? { categories: "Category" } : { related: "Category " };

  let filterLabels = {
    types: "Type",
    liveStatus: "Live",
    translated: "Translated",
    filterSites: "Site",
    ...labelCategory,
  };

  return (
    <MainWrapper
      title="Global Data"
      rightButton={rightButtonProps}
      availableLanguages={languageProps.globalLangs}
      language={languageProps.lang}
      languageActions={languageProps.languageActions}
      searchAction={setSearchQuery}
      searchValue={searchQuery}
    >
      <S.StructuredDataWrapper ref={wrapperRef}>
        <ContentFilters
          current={filter}
          dynamicValues={structuredData.global}
          onClick={handleMenuClick}
          addNew={addNewAction}
          isAllowedToCreate={allowedToCreatePages}
        />
        <S.TableListWrapper>
          {!isDataEditable && (
            <S.NotificationWrapper>
              <Notification type="info" text={notEditableText} closeButton={false} />
            </S.NotificationWrapper>
          )}
          <ErrorToast />
          {!!currentSiteErrorPages.length && <Notification type="error" text={errorPagesText} />}
          {notification && (
            <Notification
              type="warning"
              text={notification.text}
              subErrors={notification.subErrors}
              resetError={() => setNotification(null)}
            />
          )}
          <TableList
            tableHeader={TableHeader}
            pagination={pagination}
            onScroll={onScroll}
            hasFixedHeader={true}
            tableRef={tableRef}
          >
            <>
              <S.SearchTags>
                <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
                <FilterTagsBar
                  filters={filterValues[currentStructuredData?.id || "all"]}
                  setFilters={setFiltersSelection}
                  labels={filterLabels}
                  resetFilters={resetFilterQuery}
                />
              </S.SearchTags>
              {!isEmpty ? (
                content
              ) : (
                <S.EmptyWrapper>
                  <EmptyState {...emptyStateProps} />
                </S.EmptyWrapper>
              )}
            </>
          </TableList>
        </S.TableListWrapper>
      </S.StructuredDataWrapper>
      <Modal isOpen={isNewOpen} hide={toggleNewModal} size="M" title="New content">
        <OptionTable
          selectPage={addTemplate}
          filters={options.filters}
          values={options.values}
          selectedValue={selectedValue}
          mainAction={{ onClick: addNewPage, title: "Add" }}
          secondaryAction={{ title: "Cancel", onClick: toggleNewModal }}
        />
      </Modal>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={{ title: "Delete pages", onClick: bulkDelete }}
        secondaryModalAction={{ title: "Cancel", onClick: toggleDeleteModal }}
        {...{ arePagesTranslated, deleteAllVersions, setDeleteAllVersions }}
      />
      {isDataToast && <Toast action={undoDeleteData} setIsVisible={setIsDataToast} message={"Data deleted"} />}
      {isPageToast && (
        <Toast
          action={undoDeletePage}
          setIsVisible={setIsPageToast}
          message={`${deletedCount} Page${deletedCount > 1 ? "s" : ""} deleted`}
        />
      )}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  structuredData: state.structuredData.structuredData,
  currentDataContent: state.structuredData.currentDataContent,
  currentStructuredData: state.structuredData.currentStructuredData,
  filter: state.structuredData.currentFilter,
  totalItems: state.sites.totalItems,
  lang: state.app.lang,
  globalLangs: state.app.globalLangs,
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  activatedDataPacks: state.dataPacks.activated,
  isLoading: state.app.isLoading,
  template: state.pageEditor.template,
  currentSitePages: state.sites.currentSitePages,
  currentSiteErrorPages: state.sites.currentSiteErrorPages,
  skipReviewOnPublish: state.app.globalSettings.skipReviewOnPublish,
  contentFilters: state.structuredData.contentFilters,
  sites: state.sites.sites,
});

const mapDispatchToProps = {
  setLanguage: appActions.setLanguage,
  setHistoryPush: appActions.setHistoryPush,
  getStructuredDataContents: structuredDataActions.getStructuredDataContents,
  resetForm: structuredDataActions.resetForm,
  setSelectedStructuredData: structuredDataActions.setSelectedStructuredData,
  deleteDataContent: structuredDataActions.deleteStructuredDataContent,
  restoreDataContent: structuredDataActions.restoreStructuredDataContent,
  setDataStatus: structuredDataActions.setStatusStructuredDataContent,
  setFilter: structuredDataActions.setFilter,
  setCurrentPageID: pageEditorActions.setCurrentPageID,
  addTemplate: pageEditorActions.addTemplate,
  setCurrentPageStatus: pageEditorActions.setCurrentPageStatus,
  setCurrentPageName: pageEditorActions.setCurrentPageName,
  getGlobalPages: sitesActions.getGlobalPages,
  updatePageStatus: pageEditorActions.updatePageStatus,
  deleteBulk: pageEditorActions.deleteBulk,
  restorePage: pageEditorActions.restorePage,
  resetPageEditor: pageEditorActions.resetPageEditor,
  getAnalytics: analyticsActions.getAnalytics,
  setCurrentDataID: structuredDataActions.setCurrentDataID,
  resetCurrentSiteErrorPages: sitesActions.resetCurrentSiteErrorPages,
  setContentFilters: structuredDataActions.setContentFilters,
  checkUserSession: appActions.checkUserSession,
};

interface IDispatchProps {
  setHistoryPush(route: string, isEditor?: boolean): void;
  resetForm(setDefault?: boolean): void;
  setLanguage(lang: { locale: string; id: number }): void;
  getStructuredDataContents(params: IGetStructuredDataParams): Promise<void>;
  setSelectedStructuredData(id: string, scope: string): void;
  deleteDataContent(dataID: number[]): Promise<boolean>;
  restoreDataContent(dataID: number | number[]): void;
  setDataStatus(dataID: number[], status: string): void;
  setFilter(filter: string | null): void;
  setCurrentPageID(currentPageID: number | null): ISetCurrentPageIDAction;
  addTemplate(template: string): void;
  setCurrentPageStatus(currentPageStatus: string | null): ISetCurrentPageStatusAction;
  setCurrentPageName(currentPageName: string): ISetCurrentPageNameAction;
  getGlobalPages(params: IGetGlobalPagesParams, currentFilterQuery: string): Promise<void>;
  updatePageStatus(ids: number[], status: string, updatePageStatus?: boolean): Promise<boolean>;
  deleteBulk(ids: number[]): Promise<boolean>;
  restorePage(id: number | number[]): Promise<boolean>;
  resetPageEditor(): Promise<void>;
  getAnalytics(): void;
  setCurrentDataID(id: number | null): void;
  resetCurrentSiteErrorPages: () => Promise<void>;
  setContentFilters(contentFilters: Record<string, IStructuredDataQueryValues> | null): void;
  checkUserSession(): Promise<void>;
}

interface ICategoriesProps {
  structuredData: { global: IStructuredData[]; site: IStructuredData[] };
  currentDataContent: IStructuredDataContent[];
  currentStructuredData: IStructuredData | null;
  filter: string;
  totalItems: number;
  lang: { locale: string; id: number | null };
  globalLangs: ILanguage[];
  currentSiteID: number | null;
  activatedDataPacks: IDataPack[];
  isLoading: boolean;
  template: string;
  currentSitePages: IPage[];
  currentSiteErrorPages: number[];
  skipReviewOnPublish?: boolean;
  contentFilters: Record<string, IStructuredDataQueryValues> | null;
  sites: ISite[];
}

type IProps = ICategoriesProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(StructuredDataList);
